import React from 'react';
import { graphql } from 'gatsby';

import Issue from '../components/Issue';

const IssueTemplate = ({ data }) => {
  const issue = data.issue.data;

  return <Issue issue={issue} />;
};

export const query = graphql`
  query ($slug: String!) {
    issue(data: { slug: { eq: $slug } }) {
      ...IssueData
    }
  }
`;

export default IssueTemplate;
